import React, { useState, useEffect, useContext } from "react";
import { BiPlus, BiX, BiTrash, BiCheck } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { SocketContext } from "../../app/socket";
import { setCurrentClassId } from "./classNamesSlice";
import { iconStyle } from "../../utils/generalUtils";

function ClassItem({
  classItem,
  index,
  activeClassIndex,
  activeChapterIndex,
  profile,
  chapterSubmenu,
  classItemClick,
  populateChapterSubmenu,
  disabled,
}) {
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [addChapterShow, setAddChapterShow] = useState(false);
  const [newChapterName, setNewChapterName] = useState("");

  useEffect(() => {
    setShowDeleteOptions(false);
  }, [activeClassIndex]);

  const addChapter = () => {
    const body = {
      chapter_name: newChapterName,
      class_id: activeClassIndex,
    };
    socketContext.socket.emit("addChapter", body);
    setAddChapterShow(false);
    setNewChapterName("");
  };

  const deleteClass = () => {
    const eventBody = {
      class_id: classItem.class_id,
    };

    socketContext.socket.emit("deleteClass", eventBody);
    dispatch(setCurrentClassId(-1));
  };

  return (
    <div
      key={"sideFilterClassaaa" + index}
      className={"side-filters__nav-list-item " + (disabled ? " disabled" : "")}
    >
      <div
        className={
          "side-filters__nav-list-item-button " +
          (activeClassIndex === classItem.class_id && activeChapterIndex === -1
            ? "active"
            : "")
        }
        onClick={(e) => classItemClick(e, classItem.class_id)}
      >
        <span>
          {classItem.class_name}&nbsp;
          <span className="grade">{classItem.grade_name}</span>
        </span>
        {profile.user_type === "admin" && (
          <div className="side-filters__nav-list-item-button-options">
            {/* {!showDeleteOptions && (
              <BiTrash
                className="delete-icon"
                size={"25px"}
                color={"#FF6D6D"}
                style={iconStyle("transparent")}
                onClick={() => setShowDeleteOptions(true)}
              />
            )} */}
            {showDeleteOptions && (
              <div onClick={() => setShowDeleteOptions(false)}>
                <BiCheck
                  className="delete-icon"
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                  onClick={() => deleteClass()}
                />
                <BiX
                  className="delete-icon"
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {chapterSubmenu && (
        <div className={"side-filters__nav-list-item-chapters"}>
          {activeClassIndex !== classItem.class_id && (
            <div className="chapter-list preview">
              <div className="chapter-list__item"></div>
            </div>
          )}
          {activeClassIndex === classItem.class_id &&
            profile.user_type === "admin" && (
              <div className="chapter-list">
                {/* <div
                  className="chapter-list__item add "
                  onClick={() => setAddChapterShow(true)}
                >
                  <BiPlus
                    className="chapter-list__item-icon"
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span>Προσθήκη Κεφαλαίου</span>
                </div> */}
                {addChapterShow && (
                  <div className="chapter-list__item-add">
                    <input
                      className="input"
                      placeholder="Όνομα κεφαλαίου"
                      onChange={(e) => setNewChapterName(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addChapter();
                        }
                      }}
                    />
                    {/* <div className="chapter-list__item-add-controls">
                      <BiX
                        className="chapter-list__item-icon"
                        size={"30px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                        onClick={() => setAddChapterShow(false)}
                      />
                      <BiCheck
                        className="chapter-list__item-icon"
                        size={"30px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                        onClick={() => addChapter()}
                      />
                    </div> */}
                  </div>
                )}
                {populateChapterSubmenu()}
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default ClassItem;
