import "./homepage.scss";
import Calendar from "../../components/Calendar/calendar";
import Announcments from "../../components/Announcements/announcements";
import { useHomepageContext } from "./homepageContext";

function HomepageWrapper() {
  const { modalOpen } = useHomepageContext();
  return (
    <div className={" homepage  " + (modalOpen ? "modal-open" : "")}>
      <Announcments />
      <Calendar />
    </div>
  );
}

export default HomepageWrapper;
