import React, { useContext, useEffect, useRef, useState } from "react";
import "./chapters.scss";
import { useSelector } from "react-redux";
import {
  BiCheck,
  BiChevronDown,
  BiChevronUp,
  BiMinus,
  BiPlus,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";

function Chapters({ search }) {
  const socketContext = useContext(SocketContext);

  const searchRef = useRef(0);

  const [addOpen, setAddOpen] = useState(false);
  const [addName, setAddName] = useState("");

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    if (searchRef.current > 0) {
      const cleanUpGetChapters = getChapters();
      return () => {
        cleanUpGetChapters();
      };
    } else {
      searchRef.current++;
    }
  }, [search]);

  useEffect(() => {
    const cleanUpGetChapters = getChapters();
    return () => {
      cleanUpGetChapters();
    };
  }, [sideFiltersClassId]);

  const getChapters = () => {
    let args = {
      class_id: sideFiltersClassId,
      search: search,
    };
    const getChaptersListener = (data) => {
      setChapters(data);
    };

    const refreshChaptersListener = () => {
      socketContext.socket.emit("getChapters", args);
    };

    socketContext.socket.on("chapters", getChaptersListener);
    socketContext.socket.emit("getChapters", args);
    socketContext.socket.on("refreshChapters", refreshChaptersListener);

    return () => {
      socketContext.socket.off("getChapters", getChaptersListener);
      socketContext.socket.off("chapters", getChaptersListener);
      socketContext.socket.off("refreshChapters", refreshChaptersListener);
    };
  };

  const populateChapters = () => {
    return chapters.map((chapter, index) => {
      return (
        <div key={"chapter" + chapter.id} className="chapters__list-item">
          <span>{chapter.chapter_name}</span>
          <div className="chapters__list-item-actions">
            <BiMinus
              size={"25px"}
              color={"#fff"}
              style={iconStyle("trasparent")}
              onClick={() => deleteChapter(chapter.id)}
            />
          </div>
        </div>
      );
    });
  };

  const saveChapter = () => {
    const body = {
      chapter_name: addName,
      class_id: sideFiltersClassId,
    };
    socketContext.socket.emit("addChapter", body);
    setAddOpen(false);
    setAddName("");
  };

  const deleteChapter = (chapterId) => {
    const body = {
      chapter_id: chapterId,
    };
    socketContext.socket.emit("deleteChapter", body);
    // setActiveChapterIndex(-1);
  };

  return (
    <div className="chapters">
      <div className="chapters__header">
        <span>Κεφάλαια</span>
      </div>
      <div className="chapters__list">
        <div
          className={"chapters__list-add " + (addOpen ? "open" : "closed")}
          onClick={() => {
            if (!addOpen) {
              setAddOpen(true);
            }
          }}
        >
          {addOpen ? (
            <div className="chapters__list-add-wrapper">
              <input
                value={addName}
                onChange={(e) => setAddName(e.target.value)}
                placeholder="'Ονομα κεφαλαίου..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevents the default action of inserting a newline
                    saveChapter();
                  }
                }}
              />
              <BiX
                className="chapters__list-add-action"
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("trasparent")}
                onClick={() => setAddOpen(false)}
              />
              <BiCheck
                className="chapters__list-add-action"
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("trasparent")}
                onClick={() => saveChapter()}
              />
            </div>
          ) : (
            <BiPlus
              size={"25px"}
              color={"#cccccc"}
              style={iconStyle("trasparent")}
            />
          )}
        </div>
        {populateChapters()}
      </div>
    </div>
  );
}

export default Chapters;
