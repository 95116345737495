import React, { useState, useEffect } from "react";
import "./image-slider.scss";
import { iconStyle } from "../../utils/generalUtils";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const ImageSlider = ({ activeAsset, assets }) => {
  // Initialize currentIndex safely by ensuring it's a valid index
  const findIndex = (asset) =>
    assets.findIndex((a) => typeof a === "string" && a === asset);
  const [currentIndex, setCurrentIndex] = useState(findIndex(activeAsset));

  useEffect(() => {
    const index = findIndex(activeAsset);
    if (index !== -1) setCurrentIndex(index);
    console.log(assets);
  }, [activeAsset, assets]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : assets.length - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % assets.length);
  };

  const renderAsset = (asset) => {
    if (typeof asset === "string") {
      if (asset.endsWith(".pdf") || asset.endsWith(".PDF")) {
        return (
          <iframe
            src={asset}
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
          />
        );
      } else {
        return (
          <img
            src={asset}
            alt="Slide"
            style={{ width: "100%", height: "100%" }}
          />
        );
      }
    }
    return <div>Invalid asset type</div>;
  };

  return (
    <div className="image-slider">
      <button className="asset-container__nav left" onClick={goToPrevious}>
        <BiChevronLeft
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="asset-container">{renderAsset(assets[currentIndex])}</div>
      <button className="asset-container__nav right" onClick={goToNext}>
        <BiChevronRight
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="thumbnail-container">
        {assets.map((asset, index) => (
          <img
            key={index}
            src={
              typeof asset === "string" &&
              !asset.endsWith(".pdf") &&
              !asset.endsWith(".PDF")
                ? asset
                : "../../../resources/icons/pdf-icon.png"
            }
            alt="Thumbnail"
            className={
              index === currentIndex
                ? "active"
                : "" + typeof asset === "string" &&
                  !asset.endsWith(".pdf") &&
                  !asset.endsWith(".PDF")
                ? ""
                : " pdf"
            }
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
