import "./dashboard.scss";
import SideFilters from "../../components/SideFilters/side-filters";
import ExamSection from "./sections/examSection/exam-section";
import SchoolSection from "./sections/schoolSection/school-section";
import ActiveUsers from "./sections/activeUsers/active-users";
import TuitionSection from "./sections/tuitionSection/tuition-section";
import { useSelector } from "react-redux";
import ChaptersProgressSection from "./sections/chaptersProgressSection/chaptersProgressSection";
import { useEffect } from "react";

function Dashboard() {
  const profile = useSelector((state) => state.profile.value);

  return (
    <div className={"dashboard"}>
      <SideFilters />
      <div className="dashboard__sections">
        {profile.user_type === "admin" ? <ActiveUsers /> : ""}
        {profile.user_type === "admin" ? <SchoolSection /> : ""}
        {/* {profile.user_type !== "student" ? <ChaptersProgressSection /> : ""} */}
        {/* {profile.user_type == "admin" ? <TuitionSection /> : ""} */}
        {profile.user_type !== "student" && profile.user_type !== "parent" ? (
          <ExamSection />
        ) : (
          ""
        )}
        {profile.user_type == "admin" ? <TuitionSection /> : ""}
      </div>
    </div>
  );
}

export default Dashboard;
