import "./users.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { calls } from "../../config/db_config";
import { tokenUtils } from "../../utils/token-utils";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BiPlus,
  BiX,
  BiMinus,
  BiTrash,
  BiPencil,
  BiSearch,
  BiAbacus,
  // BiInfoCircle,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SideFilters from "../../components/SideFilters/side-filters";
import "react-tabs/style/react-tabs.css";
import { SocketContext } from "../../app/socket";
import LoaderCard from "../../components/LoaderCard/loaderCard";
import { iconStyle } from "../../utils/generalUtils";
import MobileSideFilters from "../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import Departments from "./Departments/departments";
import EditDepUsers from "./editDepUsers";
import Chapters from "./sections/chapters";
import { setCurrentClassId } from "../../components/SideFilters/classNamesSlice";
import InactiveUserItem from "./inactiveUserItem";

function Users() {
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const socketContext = useContext(SocketContext);
  const [search, setSearch] = useState("");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const mobileFiltersClick = () => {
    if (!mobileFiltersOpen) {
      setMobileFiltersOpen(true);
    }
  };

  const [selectedDepartment, setSelectedDepartment] = useState(-1);

  const [professors, setProfessors] = useState([]);
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [gotProfessors, setGotProfessors] = useState(false);
  const [gotStudents, setGotStudents] = useState(false);
  const [gotParents, setGotParents] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const departmentRef = useRef(0);
  const searchRef = useRef(0);

  useEffect(() => {
    return getInactiveUsers();
  }, []);

  useEffect(() => {
    setGotStudents(false);
    setGotProfessors(false);
    const cleanUpGetProfessors = getProfessors(true);
    const cleanUpGetStudents = getStudents(true);
    const cleanUpGetParents = getParents(true);
    setSelectedDepartment(-1);
    return () => {
      cleanUpGetProfessors();
      cleanUpGetStudents();
      cleanUpGetParents();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    if (departmentRef.current > 0) {
      setGotStudents(false);
      setGotProfessors(false);
      const cleanUpGetProfessors = getProfessors();
      const cleanUpGetStudents = getStudents();
      const cleanUpGetParents = getParents();
      return () => {
        cleanUpGetProfessors();
        cleanUpGetStudents();
        cleanUpGetParents();
      };
    } else {
      departmentRef.current++;
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (searchRef.current > 0) {
      setGotStudents(false);
      setGotProfessors(false);
      setGotParents(false);
      const cleanUpGetProfessors = getProfessors();
      const cleanUpGetStudents = getStudents();
      const cleanUpGetParents = getParents();
      return () => {
        cleanUpGetProfessors();
        cleanUpGetStudents();
        cleanUpGetParents();
      };
    } else {
      searchRef.current++;
    }
  }, [search]);

  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [showAllInactiveUsers, setShowAllInactiveUsers] = useState(false);

  const getInactiveUsers = () => {
    let args = {};

    const getNotActivatedUsersListener = (data) => {
      setInactiveUsers(data);
    };

    const refreshNotActivatedUsersListener = () => {
      socketContext.socket.emit("getNotActivatedUsers", args);
    };

    socketContext.socket.on("notActivatedUsers", getNotActivatedUsersListener);
    socketContext.socket.emit("getNotActivatedUsers", args);
    socketContext.socket.on(
      "refreshNotActivatedUsers",
      refreshNotActivatedUsersListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off(
        "getNotActivatedUsers",
        getNotActivatedUsersListener
      );
      socketContext.socket.off(
        "notActivatedUsers",
        getNotActivatedUsersListener
      );
      socketContext.socket.off(
        "refreshNotActivatedUsers",
        refreshNotActivatedUsersListener
      );
    };
  };

  const inactiveUserActivate = (user) => {
    // console.log("activating user");
  };

  const populateInactiveUsers = () => {
    return inactiveUsers.map((user) => {
      return (
        <InactiveUserItem
          key={"inactive-user" + user.user_id}
          user={user}
          inactiveUserActivate={inactiveUserActivate}
        />
      );
    });
  };

  const getProfessors = (newClassId) => {
    const uniqueId = "users-professors";
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "professor",
      also_admins: true,
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
    };

    const getProfessorsListener = (data) => {
      setGotProfessors(true);
      setProfessors(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshProfessorsListener
      );
    };
  };

  const getStudents = (newClassId) => {
    const uniqueId = "users-students";
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "student",
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
    };

    const getProfessorsListener = (data) => {
      setGotStudents(true);
      setStudents(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshProfessorsListener
      );
    };
  };

  const getParents = (newClassId) => {
    const uniqueId = "users-parents";
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "parent",
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
    };

    const getProfessorsListener = (data) => {
      setGotParents(true);
      setParents(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshProfessorsListener
      );
    };
  };

  const populateUsers = (type) => {
    return (
      type == "professor" ? professors : type == "student" ? students : parents
    ).map((user, index) => {
      return (
        <Link
          to={"/profile?user-id=" + user.user_id}
          key={"professor-user" + index}
          className="users__list-wrapper-item"
        >
          <div className="profile-img">
            <img
              src={
                user.profile_picture
                  ? user.profile_picture
                  : "resources/student.png"
              }
            />
          </div>
          <div className="name">
            <span>{user.last_name}</span>
            <span>{user.first_name}</span>
          </div>
        </Link>
      );
    });
  };

  // const dispatch = useDispatch();

  const deleteClass = () => {
    setShowDelete(false);
    const eventBody = {
      class_id: sideFiltersClassId,
    };

    socketContext.socket.emit("deleteClass", eventBody);
    window.location.reload();
    // dispatch(setCurrentClassId(-1));
  };

  return (
    <div className="users-page">
      <SideFilters type="panel" addClassShow="true" />
      <div className="users__header">
        <div
          className={
            "users__header-search " + (mobileFiltersOpen ? " closed" : "")
          }
          onClick={() => setMobileFiltersOpen(false)}
        >
          <input
            placeholder="Αναζήτηση"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BiSearch
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
        </div>
        <div
          className={
            "users__header-filters " + (mobileFiltersOpen ? "open" : "closed")
          }
          onClick={() => mobileFiltersClick()}
        >
          <div className="professor-exams__mobile-filters-preview">
            {mobileFiltersOpen ? (
              <span onClick={() => setMobileFiltersOpen(false)}>
                Φίλτρα
                <BiX
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </span>
            ) : (
              <BiAbacus
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>
          <MobileSideFilters bigWidth={true} />
        </div>
      </div>
      {sideFiltersClassId === -1 && inactiveUsers.length > 0 ? (
        <div className="inactive-users">
          <div className="title">Χρήστες προς ενεργοποίηση</div>
          <div className={"list " + (showAllInactiveUsers ? "open" : "")}>
            <AnimatePresence>{populateInactiveUsers()}</AnimatePresence>
          </div>
          {inactiveUsers.length > 4 && (
            <div>
              <button
                className={
                  "list__show-all " +
                  (inactiveUsers.length > 3 ? "" : "hide ") +
                  (showAllInactiveUsers ? " hide" : "")
                }
                onClick={() => setShowAllInactiveUsers(true)}
              >
                Προβολή όλων{" "}
                <BiPlus
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </button>
              <button
                className={
                  "list__show-all " +
                  (inactiveUsers.length > 3 ? "" : "hide ") +
                  (showAllInactiveUsers ? "" : " hide")
                }
                onClick={() => setShowAllInactiveUsers(false)}
              >
                Προβολή λιγότερων{" "}
                <BiMinus
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 ? (
        <Chapters classId={sideFiltersClassId} search={search} />
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 ? (
        <Departments
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          search={search}
        />
      ) : (
        ""
      )}
      <div className="users__list">
        <div className="users__list-label">
          <span className="users__title">
            Καθηγητές <span>({professors.length})</span>
          </span>
        </div>
        <div className="users__list-wrapper">
          {selectedDepartment != -1 && gotProfessors ? (
            <EditDepUsers
              currentUsers={professors}
              setCurrentUsers={setProfessors}
              userType="professor"
              selectedDepartment={selectedDepartment}
            />
          ) : (
            ""
          )}
          {!gotProfessors ? (
            <LoaderCard cardSum={5} width={20} />
          ) : (
            populateUsers("professor")
          )}
        </div>
      </div>
      <div className="users__list">
        <div className="users__list-label">
          <span className="users__title">
            Μαθητές <span>({students.length})</span>
          </span>
        </div>
        <div className="users__list-wrapper">
          {selectedDepartment != -1 && gotStudents ? (
            <EditDepUsers
              currentUsers={students}
              setCurrentUsers={setStudents}
              userType="student"
              selectedDepartment={selectedDepartment}
            />
          ) : (
            ""
          )}
          {!gotStudents ? (
            <LoaderCard cardSum={5} width={20} />
          ) : (
            populateUsers("student")
          )}
        </div>
      </div>
      {sideFiltersClassId == -1 ? (
        <div className="users__list">
          <div className="users__list-label">
            <span className="users__title">
              Γονείς <span>({parents.length})</span>
            </span>
          </div>
          <div className="users__list-wrapper">
            {!gotParents ? (
              <LoaderCard cardSum={5} width={20} />
            ) : (
              populateUsers("parent")
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 ? (
        <div className="users__delete-class">
          <button className="cta cta-red" onClick={() => setShowDelete(true)}>
            Διαγραφή Μαθήματος
          </button>
        </div>
      ) : (
        ""
      )}
      <div className={"delete-modal " + (showDelete ? "show" : "")}>
        <div className="delete-container">
          <span className="title">
            Είστε σίγουροι ότι θέλετε να διαγράψετε το επιλεγμένο μάθημα; Μαζι
            του θα διαγραφούν και όλα τα στατιστικά, διαγωνίσματα κτλ
          </span>
          <div className="controls">
            <button className="cta" onClick={() => setShowDelete(false)}>
              Ακύρωση
            </button>
            <button className="cta cta-red" onClick={() => deleteClass()}>
              Διαγραφή
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
