import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./add-users-slide.scss";
import {
  BiPlus,
  BiX,
  BiMinus,
  BiTrash,
  BiPencil,
  BiSearch,
  BiAbacus,
  BiCheck,
  // BiInfoCircle,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "../../../../app/socket";
import { iconStyle } from "../../../../utils/generalUtils";
import UserItem from "./userItem";

function AddUsersSlide({ userType, selectedUsers, setSelectedUsers }) {
  const socketContext = useContext(SocketContext);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const cleanUpGetUsers = getUsers();
    return () => {
      cleanUpGetUsers();
    };
  }, []);

  const getUsers = () => {
    const uniqueId =
      userType == "professor" ? "new-dep-prof" : "new-dep-student";
    const args = {
      uniqe_id: uniqueId,
      type: userType,
      also_admins: userType == "professor" ? true : false,
    };

    const getUsersListener = (data) => {
      setUsers(data);
    };

    const refreshUsersListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on("allUsersWithParams" + uniqueId, getUsersListener);
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on("refreshAllUsersWithParams", refreshUsersListener);

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getUsersListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshUsersListener
      );
    };
  };

  const addUserToCurrentUsers = (user) => {
    setSelectedUsers((prevCurrentUsers) => [...prevCurrentUsers, user]);
  };

  const removeFromCurrentUsers = (user) => {
    setSelectedUsers((prevCurrentUsers) =>
      prevCurrentUsers.filter(
        (currentUser) => currentUser.user_id !== user.user_id
      )
    );
  };

  const populateCurrentUsers = () => {
    return selectedUsers.map((user, index) => (
      <UserItem
        key={"currentusr" + user.user_id}
        user={user}
        type="remove"
        handleClick={() => removeFromCurrentUsers(user)}
      />
    ));
  };

  const populateAllUsers = () => {
    const filteredUsers = users.filter(
      (user) =>
        !selectedUsers.some(
          (currentUser) => currentUser.user_id === user.user_id
        )
    );
    return filteredUsers.map((user, index) => (
      <UserItem
        key={"allusr" + user.user_id}
        user={user}
        type="add"
        handleClick={() => addUserToCurrentUsers(user)}
      />
    ));
  };

  return (
    <div className={"add-users-slide no-scrollbar"}>
      <span className="add-users-slide__title">
        Επιλέξτε τους {userType == "professor" ? "καθηγητές" : "μαθητές"} του
        τμήματος
      </span>
      <span className="add-users-slide__label">Επιλεγμένοι</span>
      <div className="add-users-slide__list">
        <AnimatePresence> {populateCurrentUsers()}</AnimatePresence>
      </div>
      <span className="add-users-slide__label">
        Όλοι οι {userType == "professor" ? "Καθηγητές" : "Μαθητές"}
      </span>
      <div className="add-users-slide__list">
        <AnimatePresence> {populateAllUsers()}</AnimatePresence>
      </div>
    </div>
  );
}

export default AddUsersSlide;
