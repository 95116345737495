import React, { useEffect, useState } from "react";
import "./quizItemStudent.scss";
import QuizAnswerStudent from "./quizAnswerStudent";
import QuizMatchingAnswer from "./quizMatchingAnswer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ButtonMultipleToggle from "../../components/ButtonMultipleToggle/buttonMultipleToggle";
import { BiX } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function QuizItemStudent({ question, nextSlide }) {
  const [answered, setAnswered] = useState(false);

  const [showSucces, setShowSuccess] = useState(false);
  const [showWrong, setShowWrong] = useState(false);

  const [matchedAnswers, setMatchedAnswers] = useState([]);

  const [toggleType, setToggleType] = useState("Λάθος");

  const hasMultipleCorrect =
    question.answers.filter((ans) => ans.is_correct == 1).length > 1;

  const toggleTypeNum = 2;

  const answerSubmit = () => {
    setAnswered(true);
    question.answered = true;
    let correct = true;
    if (question.type === "correct/wrong" && !question.is_multiple) {
      if (
        (question.is_correct && toggleType == "Σωστό") ||
        (!question.is_correct && toggleType == "Λάθος")
      ) {
        correct = true;
      } else {
        correct = false;
      }
    } else {
      question.answers.map((answer) => {
        if (question.type === "multiple-choice") {
          if (
            (!answer.is_correct && answer.selected) ||
            (answer.is_correct && !answer.selected)
          ) {
            correct = false;
          }
        }
        if (question.type === "correct/wrong" && question.is_multiple) {
          if (
            (answer.is_correct && !answer.selected) ||
            (!answer.is_correct && answer.selected)
          ) {
            correct = false;
          }
        }
        if (question.type === "matching") {
          if (matchedAnswers.length === question.answers.length) {
            const updatedAnswers = matchedAnswers.map((match) => ({
              ...match,
              correct: match.answerA.id === match.answerB.id,
            }));
            correct = updatedAnswers.every((match) => match.correct);
          } else {
            correct = false;
          }
        }
      });
    }
    if (question.type === "matching") {
      question.pairs = matchedAnswers;
    }
    if (correct) {
      setShowSuccess(true);
    } else {
      setTimeout(() => {
        setShowWrong(true);
      }, [200]);
    }
    question.correct = correct;
    setTimeout(() => {
      setShowSuccess(false);
      setShowWrong(false);
    }, 1900);
    nextSlide();
  };

  const [showHintBox, setShowHintBox] = useState(false);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    if (question.type === "matching") {
      question.pairs = matchedAnswers;
    }
  }, [matchedAnswers]);

  const showHintClick = () => {
    setShowHintBox(true);
    setTimeout(() => {
      setShowHint(true);
    }, 100);
  };

  const closeHintBox = () => {
    setShowHintBox(false);
    setShowHint(false);
  };

  const populateHint = () => {
    return (
      <div
        className={
          "quiz-student__slide-header-hint " + (showHintBox ? "open" : "")
        }
      >
        <div
          className={
            "quiz-student__slide-header-hint-button " +
            (showHintBox ? "hide" : "")
          }
          onClick={showHintClick}
        >
          i
        </div>
        <div
          className={
            "quiz-student__slide-header-hint-content " +
            (showHint ? "" : "hide")
          }
        >
          <button
            className="quiz-student__slide-header-hint-close"
            onClick={closeHintBox}
          >
            <BiX
              size={"30px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
            />
          </button>
          <label>{question.hint}</label>
        </div>
      </div>
    );
  };

  const populateAnswers = (question) => {
    return question.answers.map((answer, index) => {
      return (
        <QuizAnswerStudent
          key={"question-answer" + index + question.exercises_id}
          answer={answer}
          type={question.type}
          answered={answered}
          hasMultipleCorrect={hasMultipleCorrect}
        />
      );
    });
  };

  const canAnswer = () => {
    if (question.type == "multiple-choice") {
      return question.answers.some((ans) => ans.selected);
    } else {
      return true;
    }
  };

  return (
    <div className="quiz-student__slide">
      <div className="quiz-student__slide-content no-scrollbar">
        <div
          className={
            "quiz-student__slide-header " +
            (!question.is_multiple &&
            question.type == "correct/wrong" &&
            showSucces
              ? " show-sucess"
              : "") +
            (!question.is_multiple &&
            question.type == "correct/wrong" &&
            showWrong
              ? " show-wrong"
              : "")
          }
        >
          <div className="wrapper">
            <h5 className="editor-read-only">
              <CKEditor
                className="nalpastesting"
                editor={ClassicEditor}
                data={question.description}
                config={{
                  toolbar: [], // Configures an empty toolbar
                }}
                disabled={true} // Disable editing
              />
            </h5>
            {question.type == "correct/wrong" && !question.is_multiple ? (
              <ButtonMultipleToggle
                button1="Σωστό"
                button2="Λάθος"
                setSelected={setToggleType}
                selected={toggleTypeNum}
              ></ButtonMultipleToggle>
            ) : (
              ""
            )}
          </div>
          {question.hint ? populateHint() : ""}
        </div>
        {question.type != "correct/wrong" ||
        (question.type == "correct/wrong" && question.is_multiple) ? (
          <div className="details">
            <ul className="quiz-student__answers">
              {question.type !== "matching" && populateAnswers(question)}
              {question.type === "matching" && (
                <QuizMatchingAnswer
                  answers={question.answers}
                  answered={answered}
                  matchedAnswers={matchedAnswers}
                  setMatchedAnswers={setMatchedAnswers}
                />
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex justify-content-center align-items-center pt-4 flex-column">
          {canAnswer() ? (
            <div
              className={
                "quiz-student__answer-btn " +
                (question.answered ? " answered" : "")
              }
              onClick={answerSubmit}
            >
              {!question.answered && <>Απάντηση</>}
              {question.answered && <>Απαντήθηκε</>}
            </div>
          ) : (
            ""
          )}
        </div>
        {showSucces && (
          <div className={"quiz-student__success"}>
            <div className="success-animation">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  class="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  class="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          </div>
        )}

        {showWrong && (
          <div className={"quiz-student__wrong"}>
            <div className="error-animation">
              <svg
                className="crossmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="crossmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="crossmark__cross"
                  fill="none"
                  d="M16 16 l20 20"
                />
                <path
                  className="crossmark__cross"
                  fill="none"
                  d="M36 16 l-20 20"
                />
              </svg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuizItemStudent;
