exports.isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

exports.iconStyle = (bgColor) => {
  return {
    background: bgColor,
    padding: "5px",
    borderRadius: "50%",
    marginBottom: "8px",
  };
};

exports.isDevEnv = () => {
  return process.env.REACT_APP_ENV === "DEV";
};
