import "./tuition-section.scss";
// import { BiPulse, BiUser, BiUserCheck, BiUserVoice } from "react-icons/bi";
import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../../app/socket";
// import { Link } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
// import { CircularProgressbar } from "react-circular-progressbar";

function TuitionSection() {
  const socketContext = useContext(SocketContext);

  // const donutRef = useRef(null);

  const [tuitionIsMonthly, setTuitionIsMonthly] = useState(false);
  const [monthlyTuition, setMonthlyTuition] = useState([]);
  const [totalTuition, setTotalTuition] = useState(0);
  const [totalTuitionEarned, setTotalTuitionEarned] = useState(0);
  const [detailedTuition, setDetailedTuition] = useState([]);
  const [totalTuitionForGrades, setTotalTuitionForGrades] = useState([]);
  // const [gotTuition, setGotTuition] = useState(false);

  const horizontalOptions = {
    indexAxis: "y", // This will make the chart horizontal
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right", // You can adjust the legend position or set it to false if you don't want it
      },
      title: {
        display: true,
        text: "Tuition Overview",
      },
    },
  };

  const horizontalData = {
    labels: ["Total Tuition", "Total Tuition Earned"],
    datasets: [
      {
        label: "Tuition",
        data: [totalTuition, totalTuitionEarned],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      },
    ],
  };

  const donutData = {
    labels: [
      "Προβλεπόμενα Δίδακτρα " + totalTuition + "€",
      "Πληρωμένα Δίδακτρα " + totalTuitionEarned + "€",
    ],
    datasets: [
      {
        data: [totalTuition, totalTuitionEarned], // Values for each label
        backgroundColor: ["#6124e681", "#ffd53d92"],
        borderColor: ["#6225e6", "#ffd43d"],
        borderWidth: 1,
      },
    ],
  };

  const donutOptions = {
    cutoutPercentage: 50, // The percentage of the chart that is cut out of the middle
    responsive: true,
    maintainAspectRatio: false,
  };

  const tuitionForGradesData = {
    labels: totalTuitionForGrades.map((item) => item.grade_name),
    datasets: [
      {
        label: "Συνολικά Δίδακτρα",
        data: totalTuitionForGrades.map((item) => item.total_amount),
        backgroundColor: "#6124e681",
        borderColor: "#6225e6",
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };

  const tuitionForGradesOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Amount",
        data: [],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  });

  useEffect(() => {
    return getGeneralSettings();
  }, []);

  const getGeneralSettings = () => {
    let args = {};

    const getGeneralSettingsListener = (data) => {
      const tuitionIsMonthlySetting = () => {
        return data.find((obj) => obj.name === "tuition_is_monthly");
      };
      if (tuitionIsMonthlySetting()) {
        setTuitionIsMonthly(tuitionIsMonthlySetting().value);
      } else {
        setTuitionIsMonthly(false);
      }
    };

    const refreshGeneralSettingsListener = () => {
      socketContext.socket.emit("getGeneralSettings", args);
    };

    socketContext.socket.on("generalSettings", getGeneralSettingsListener);
    socketContext.socket.emit("getGeneralSettings", args);
    socketContext.socket.on(
      "refreshGeneralSettings",
      refreshGeneralSettingsListener
    );

    return () => {
      socketContext.socket.off(
        "getGeneralSettings",
        getGeneralSettingsListener
      );
      socketContext.socket.off("generalSettings", getGeneralSettingsListener);
      socketContext.socket.off(
        "refreshGeneralSettings",
        refreshGeneralSettingsListener
      );
    };
  };

  useEffect(() => {
    if (tuitionIsMonthly) {
      return getMonthlyTuition();
    } else {
      const cleanUpGetTotalTuition = getTotalTuition();
      const cleanUpGetTotalTuitionEarned = getTotalTuitionEarned();
      const cleanUpGetDetailedTuition = getDetailedTuition();
      const cleanUpGetTuitionForGrades = getTotalTuitionForGrades();
      return () => {
        cleanUpGetDetailedTuition();
        cleanUpGetTotalTuition();
        cleanUpGetTuitionForGrades();
        cleanUpGetTotalTuitionEarned();
      };
    }
  }, [tuitionIsMonthly]);

  const getMonthlyTuition = () => {
    let args = {};
    const getMonthlyTuitionListener = (data) => {
      console.log(" got monthly tuition ");
      console.log(data);
      setMonthlyTuition(data);
    };

    socketContext.socket.on("monthlyTuition", getMonthlyTuitionListener);
    socketContext.socket.emit("getMonthlyTuition", args);

    return () => {
      socketContext.socket.off("getMonthlyTuition", getMonthlyTuitionListener);
      socketContext.socket.off("monthlyTuition", getMonthlyTuitionListener);
    };
  };

  const getDetailedTuition = () => {
    let args = { selectOnlyPayments: true };
    const getDetailedTuitionListener = (data) => {
      setDetailedTuition(data);
      setChartData(prepareChartData(data));
    };

    socketContext.socket.on("detailedTuition", getDetailedTuitionListener);
    socketContext.socket.emit("getDetailedTuition", args);

    return () => {
      socketContext.socket.off(
        "getDetailedTuition",
        getDetailedTuitionListener
      );
      socketContext.socket.off("detailedTuition", getDetailedTuitionListener);
    };
  };

  const getTotalTuitionEarned = () => {
    let args = {};
    const getTotalTuitionEarnedListener = (data) => {
      if (data && data[0].total_amount) {
        setTotalTuitionEarned(data[0].total_amount);
      }
    };

    socketContext.socket.on(
      "totalTuitionEarned",
      getTotalTuitionEarnedListener
    );
    socketContext.socket.emit("getTotalTuitionEarned", args);

    return () => {
      socketContext.socket.off(
        "getTotalTuitionEarned",
        getTotalTuitionEarnedListener
      );
      socketContext.socket.off(
        "totalTuitionEarned",
        getTotalTuitionEarnedListener
      );
    };
  };

  const getTotalTuition = () => {
    let args = {};
    const getTotalTuitionListener = (data) => {
      if (data && data[0].total_amount) {
        setTotalTuition(data[0].total_amount);
      }
    };

    socketContext.socket.on("totalTuition", getTotalTuitionListener);
    socketContext.socket.emit("getTotalTuition", args);

    return () => {
      socketContext.socket.off("getTotalTuition", getTotalTuitionListener);
      socketContext.socket.off("totalTuition", getTotalTuitionListener);
    };
  };

  const getTotalTuitionForGrades = () => {
    let args = {};
    const getTotalTuitionForGradesListener = (data) => {
      data.map((item) => {
        if (item.total_amount < 0) {
          item.total_amount = 0;
        }
      });
      setTotalTuitionForGrades(data);
    };

    socketContext.socket.on(
      "totalTuitionForGrades",
      getTotalTuitionForGradesListener
    );
    socketContext.socket.emit("getTotalTuitionForGrades", args);

    return () => {
      socketContext.socket.off(
        "getTotalTuitionForGrades",
        getTotalTuitionForGradesListener
      );
      socketContext.socket.off(
        "totalTuitionForGrades",
        getTotalTuitionForGradesListener
      );
    };
  };

  const prepareChartData = (data) => {
    const months = [
      "Ιαν",
      "Φεβ",
      "Μάρ",
      "Απρ",
      "Μάι",
      "Ιούν",
      "Ιούλ",
      "Αύγ",
      "Σεπ",
      "Οκτ",
      "Νοέ",
      "Δεκ",
    ];
    let sumsByMonth = Array(12).fill(0);

    data.forEach((item) => {
      const date = new Date(item.time);
      const monthIndex = date.getMonth(); // 0 to 11
      sumsByMonth[monthIndex] += item.amount;
    });

    // Adjust the array to start from September and end in August
    sumsByMonth = [...sumsByMonth.slice(8), ...sumsByMonth.slice(0, 8)];
    const monthLabels = [...months.slice(8), ...months.slice(0, 8)];

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
        },
      },
      // ... you can add more options as needed
    };

    return {
      labels: monthLabels,
      datasets: [
        {
          label: "Σύνολο πληρωμών",
          data: sumsByMonth,
          fill: false,
          backgroundColor: "#6225e6",
          borderColor: "#6224e645",
          //   tension: 0.4,
        },
      ],
      options: options,
    };
  };

  return (
    <div
      className={
        "section tuition-section " + (tuitionIsMonthly ? "d-none" : "")
      }
    >
      <span className="section__title">Δίδακτρα</span>
      <div className="tuition-stats">
        <div className="tuition-stats__payments">
          <div className="graph graph-container">
            <span className="label">Σύνολο διδάκτρων ανά μήνα</span>
            <Line data={chartData} />
          </div>
          <div className="general">
            <div className="grades graph-container">
              <span className="label">Σύνολο διδάκτρων ανά τάξη</span>
              <Bar
                data={tuitionForGradesData}
                options={tuitionForGradesOptions}
              />
            </div>
            <div className="total  graph-container">
              {donutData && donutOptions ? (
                <Pie data={donutData} options={donutOptions} />
              ) : (
                // <Bar options={horizontalOptions} data={horizontalData} />
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="tuition-stats__users"></div>
      </div>
    </div>
  );
}

export default TuitionSection;
