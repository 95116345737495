import "./departments.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  BiPlus,
  BiX,
  BiMinus,
  BiTrash,
  BiPencil,
  BiSearch,
  BiAbacus,
  // BiInfoCircle,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "../../../app/socket";
import { iconStyle } from "../../../utils/generalUtils";
import AddDepartment from "./addDepartment";
import DepartmentItem from "./departmentItem";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function Departments({ selectedDepartment, setSelectedDepartment, search }) {
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const socketContext = useContext(SocketContext);
  const [departments, setDepartments] = useState([]);
  const [gotDepartments, setGotDepartments] = useState(false);

  const searchRef = useRef(0);

  useEffect(() => {
    setGotDepartments(false);
    const cleanUpGetDepartments = getDepartments();
    return () => {
      cleanUpGetDepartments();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    if (searchRef.current > 1) {
      setGotDepartments(false);
      const cleanUpGetDepartments = getDepartments();
      return () => {
        cleanUpGetDepartments();
      };
    } else {
      searchRef.current++;
    }
  }, [search]);

  const getDepartments = () => {
    let args = {
      class_id: sideFiltersClassId,
      search: search,
    };

    const getDepartmentsListener = (data) => {
      setGotDepartments(true);
      setDepartments(data);
    };

    const refreshDepartmentsListener = () => {
      socketContext.socket.emit("getDepartments", args);
    };

    socketContext.socket.on("departments", getDepartmentsListener);
    socketContext.socket.emit("getDepartments", args);
    socketContext.socket.on("refreshDepartments", refreshDepartmentsListener);

    return () => {
      socketContext.socket.off("getDepartments", getDepartmentsListener);
      socketContext.socket.off("departments", getDepartmentsListener);
      socketContext.socket.off(
        "refreshDepartments",
        refreshDepartmentsListener
      );
    };
  };

  const populateDepartments = () => {
    return departments.map((department, index) => {
      return (
        <DepartmentItem
          key={"departmentItem " + department.department_id}
          department={department}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
        />
      );
    });
  };

  return (
    <div className="users__departments">
      <span className="users__title">
        Τμήματα <span>({departments.length})</span>
      </span>
      <div className="users__departments-list">
        {gotDepartments ? (
          <AddDepartment setSelectedDepartment={setSelectedDepartment} />
        ) : (
          ""
        )}
        <div
          className={
            "users__departments-list-item " +
            (selectedDepartment == -1 ? "active" : "")
          }
          onClick={() => setSelectedDepartment(-1)}
        >
          <span className="label">Όλα</span>
        </div>
        {!gotDepartments ? (
          <LoaderCard cardSum={5} width={20} />
        ) : (
          populateDepartments()
        )}
      </div>
    </div>
  );
}

export default Departments;
