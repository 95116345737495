import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import RichTextEditor from "../RichTextEditor/richeTextEditor";
import { calls } from "../../config/db_config";
import { useSelector } from "react-redux";

function NewAnnouncement({ setShowAddModal, announcements, setAnnouncements }) {
  const socketContext = useContext(SocketContext);
  const profile = useSelector((state) => state.profile.value);
  const envVars = useSelector((state) => state.profile.environmentVars);

  const [classes, setClasses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [saveRichText, setSaveRichText] = useState(false);

  const [includeProfessors, setIncludeProfessors] = useState(true);
  const [includeStudents, setIncludeStudents] = useState(true);
  const [includeParents, setIncludeParents] = useState(true);

  const [description, setDescription] = useState("");
  const [classId, setClassId] = useState(-1);
  const [departmentId, setDepartmentId] = useState([-1]);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    // const cleanUpGetTags = getTags();
    return () => {
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    if (classId != -1) {
      return getDepartments();
    }
  }, [classId]);

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const getDepartments = () => {
    const getDepartmentsListener = (data) => {
      setDepartments(data);
    };

    socketContext.socket.on("departments", getDepartmentsListener);

    let args = { class_id: classId };
    socketContext.socket.emit("getDepartments", args);

    const refreshDepartmentsListener = () => {
      socketContext.socket.emit("getDepartments", args);
    };
    socketContext.socket.on("refreshDepartments", refreshDepartmentsListener);

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getDepartments", getDepartmentsListener);
      socketContext.socket.off("departments", getDepartmentsListener);
      socketContext.socket.off(
        "refreshDepartments",
        refreshDepartmentsListener
      );
    };
  };

  const populateClasses = () => {
    return classes.map((classItem, i) => {
      return (
        <option key={"announcementClassItem" + i} value={classItem.class_id}>
          {classItem.class_name} - {classItem.grade_name}
        </option>
      );
    });
  };

  const populateDepartments = () => {
    return departments.map((departmentItem, i) => {
      return (
        <div
          key={"announcementDepartmentItem" + i}
          className={
            "item " +
            (departmentId.includes(departmentItem.department_id)
              ? "active"
              : "")
          }
          onClick={() => departmentClick(departmentItem.department_id)}
        >
          {departmentItem.department_name}
        </div>
      );
    });
  };

  const departmentClick = (clickedId) => {
    let temp = departmentId;
    if (departmentId.length === 1 && departmentId[0] === -1) {
      temp = [];
      // setDepartmentId([]);
    }
    if (departmentId.includes(clickedId)) {
      const newDepartmentId = departmentId.filter((id) => id !== clickedId);
      if (newDepartmentId.length === 0) {
        // setDepartmentId([-1]);
        temp = [-1];
      } else {
        temp = newDepartmentId;
        // setDepartmentId(newDepartmentId);
      }
    } else {
      // setDepartmentId([...departmentId, clickedId]);
      temp = [...temp, clickedId];
    }
    setDepartmentId(temp);
  };

  useEffect(() => {
    if (saveRichText) {
      let body = {
        title: "",
        description: description,
        class_id: classId,
        department_id: JSON.stringify(departmentId),
        for_professors: includeProfessors,
        for_students: includeStudents,
        for_parents: includeParents,
      };

      const newAnnouncement = {
        announcement_id: -1,
        class_id: classId,
        created_at: new Date().toISOString(),
        created_by: profile.user_id,
        department_id: departmentId,
        description: description,
        for_parents: includeParents,
        for_professors: includeProfessors,
        for_students: includeStudents,
        title: "",
      };

      setAnnouncements([newAnnouncement, ...announcements]);

      socketContext.socket.emit("addAnnouncement", body);
      setShowAddModal(false);

      // let notificationTitle = "Νέα Ανακοίνωση";
      // let notificationDescription = "Έχεις νέα ανκοίνωση";

      // let args = {
      //   notification: {
      //     title: notificationTitle,
      //     body: notificationDescription,
      //   },
      // };

      // const notificationBody = {
      //   class_id: classId,
      //   department_id: departmentId,
      //   to_students: includeStudents,
      //   to_professors: includeProfessors,
      //   to_admins: includeProfessors,
      //   to_parents: includeParents,
      //   notification_body: args,
      //   notification_type: "new-announcement",
      // };

      // socketContext.socket.emit("sendNotification", notificationBody);
      setSaveRichText(false);
    }
  }, [description]);

  const saveAnnouncement = () => {
    setSaveRichText(true);
  };

  return (
    <div className="announcements__configure">
      <div className="title">Διαμόρφωση νέας ανακοίνωσης</div>
      <div className="info w-100 input-container">
        <span className="label">Περιγραφή</span>
        <RichTextEditor
          imagesEndpoint={`${envVars.DB_SCHOOL_NAME}/announcements`}
          postImagesEndPoint={calls.submitAnnouncementImages}
          setText={setDescription}
          saveFiles={saveRichText}
          fileNameStart="announcements"
        />
      </div>
      <div className="group">
        <div className="group__select input-container">
          <span className="label">Μάθημα</span>
          <select
            className="input"
            onChange={(e) => setClassId(e.target.value)}
          >
            <option value="-1">'Ολα</option>
            {populateClasses()}
          </select>
        </div>
        {/* <div className="group__select input-container">
          <span className="label">Τμήματα</span>
          <select
            className="input"
            onChange={(e) => setDepartmentId(e.target.value)}
          >
            <option value="-1">'Ολα</option>
            {classId != -1 && populateDepartments()}
          </select>
        </div> */}
      </div>
      {classId !== -1 && (
        <div className="departments input-container">
          <span className="label">Τμήματα</span>
          {classId !== -1 ? (
            <div className="departments__list">
              <div
                className={"item " + (departmentId == -1 ? "active" : "")}
                onClick={() => setDepartmentId([-1])}
              >
                Όλα
              </div>
              {populateDepartments()}
            </div>
          ) : (
            <span className="sublabel">
              Επιλέξτε μάθημα για να δείτε τα τμήματα
            </span>
          )}
        </div>
      )}
      <div className="target input-container">
        <span className="label">Ειδοποιήστε:</span>
        <div className="target__select">
          <div
            className="item"
            onClick={() => setIncludeProfessors(!includeProfessors)}
          >
            <div
              className={
                "target__select-radio " + (includeProfessors ? "active" : "")
              }
            ></div>
            <span>Καθηγητές</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeStudents(!includeStudents)}
          >
            <div
              className={
                "target__select-radio " + (includeStudents ? "active" : "")
              }
            ></div>
            <span>Μαθητές</span>
          </div>
          <div
            className="item"
            onClick={() => setIncludeParents(!includeParents)}
          >
            <div
              className={
                "target__select-radio " + (includeParents ? "active" : "")
              }
            ></div>
            <span>Γονείς</span>
          </div>
        </div>
      </div>
      <div className="save">
        <button onClick={() => saveAnnouncement()} className="cta">
          Δημοσίευση
        </button>
      </div>
    </div>
  );
}

export default NewAnnouncement;
