const endpoint =
  process.env.REACT_APP_ENV === "DEV" ? "http://localhost:3001" : "";

exports.calls = {
  endpoint: endpoint,
  signIn: endpoint + "/sign-in",
  googleLogin: endpoint + "/google-login",
  signUp: endpoint + "/sign-up",
  changePassword: endpoint + "/change-password",
  getData: endpoint + "/get-data",
  getQuestions: endpoint + "/get-questions?answers=true",
  getQuestionsOnly: endpoint + "/get-questions?answers=false",
  getQuestionsBySubject: endpoint + "/get-questions-by-subject",
  getQuestionsByChapterId: endpoint + "/get-questions-by-chapter-id",
  removeQuestion: endpoint + "/remove-question",
  removeUserToken: endpoint + "/remove-user-token",
  generateQuiz: endpoint + "/generate-quiz",
  getQuizResults: endpoint + "/get-quiz-results",
  answerQuestion: endpoint + "/answer-question",
  updateAnswer: endpoint + "/update-answer",
  createQuestion: endpoint + "/create-question",
  updateQuestion: endpoint + "/update-question",
  tempZip: endpoint + "/tempZip",
  addNewSchool: endpoint + "/add-new-school",
  getUserSchools: endpoint + "/get-user-schools",
  getClasses: endpoint + "/get-classes",
  getAllClasses: endpoint + "/get-all-classes",
  getChaptersByClassId: endpoint + "/get-chapters-by-class-id",
  getClassesByUser: endpoint + "/get-classes-by-user",
  getUserHasClasses: endpoint + "/get-user-has-classes",
  getSubjects: endpoint + "/get-subjects",
  updateUserHasClass: endpoint + "/update-user-has-class",
  getUserData: endpoint + "/get-user-data",
  updateProfile: endpoint + "/update-profile",
  updateProfilePicture: endpoint + "/update-profile-picture",
  saveChatPicture: endpoint + "/save-chat-picture",
  startQuizResults: endpoint + "/start-quiz-results",
  submitQuizResults: endpoint + "/submit-quiz-results",
  getChagptResponse: endpoint + "/get-chatgpt-response",
  submitExamImage: endpoint + "/submit-exam-image",
  submitCurriculumImages: endpoint + "/submit-curriculum-images",
  submitCurriculumAnswersImages: endpoint + "/submit-curriculum-answers-images",
  submitExam: endpoint + "/submit-exam",
  getExams: endpoint + "/get-exams",
  getUsers: endpoint + "/get-users",
  getExamsByName: endpoint + "/get-exams-by-name",
  getExamsMarked: endpoint + "/get-exams-marked",
  getExamsNotMarked: endpoint + "/get-exams-not-marked",
  setExamTimeStarted: endpoint + "/set-exam-time-started",
  setExamLog: endpoint + "/set-exam-log",
  getExamLogs: endpoint + "/get-exam-logs",
  setExamScore: endpoint + "/set-exam-score",
  adminSubmitExam: endpoint + "/admin-submit-exam",
  adminSubmitExamImage: endpoint + "/admin-submit-exam-images",
  studentSubmitExamImages: endpoint + "/student-submit-exam-images",
  adminSubmitQuizImages: endpoint + "/admin-submit-quiz-images",
  submitAnnouncementImages: endpoint + "/submit-announcement-images",
  getExamTimeStarted: endpoint + "/get-exam-time-started",
  getExamsSubmitted: endpoint + "/get-exams-submitted",
  getExamResultsById: endpoint + "/get-exam-results-by-id",
  getExamById: endpoint + "/get-exam-by-id",
  getEndpointLink: endpoint + "/get-endpoint-link",
  deleteExam: endpoint + "/delete-exam",
  adminEditExam: endpoint + "/admin-edit-exam",
  insertLog: endpoint + "/insert-log",
  getQuizAverageByChapter: endpoint + "/get-quiz-average-by-chapter",
  getQuizWeekAverage: endpoint + "/get-quiz-week-average",
  getLogoLink: endpoint + "/get-logo-link",
  getEnvironment: endpoint + "/get-env",

  // stats
  getQuizStats: endpoint + "/get-quiz-stats",
  getExamStats: endpoint + "/get-exam-stats",
  getExamCount: endpoint + "/get-exam-count",
  getUsersCount: endpoint + "/get-users-count",
  getQuizCount: endpoint + "/get-quiz-count",
  getExamScoresLmh: endpoint + "/get-exam-scores-lmh",
  getExamStudentStats: endpoint + "/get-exam-student-stats",
  getExamStudentCounts: endpoint + "/get-exam-student-counts",
  getExamStudentCountsLatest: endpoint + "/get-exam-student-counts-latest",
  getExamsAverages: endpoint + "/get-exams-averages",
  getQuizAverages: endpoint + "/get-quiz-averages",

  // user stats
  getUserQuizAveragePerMonth: endpoint + "/get-user-quiz-average-per-month",
  getUserQuizAverage: endpoint + "/get-user-quiz-average",
  getUserQuizScoresLmh: endpoint + "/get-user-quiz-scores-lmh",
  getUserExamStats: endpoint + "/get-user-exam-stats",
  getUserExamTimes: endpoint + "/get-user-exam-times",
  getUserOverallGrade: endpoint + "/get-user-overall-grade",
};
