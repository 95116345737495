import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../../app/socket";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronRight,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../../../utils/generalUtils";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";

function CommentItem({ event, student, comment, selectedDate }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [commentText, setCommentText] = useState("");
  const [commentScore, setCommentScore] = useState(35);

  const saveComment = () => {
    if (commentText) {
      let body = {
        to_user_id: student.user_id,
        from_user_id: profile.user_id,
        title: event.title,
        description: commentText,
        class_id: event.class_id,
        notify_parent: true,
        notify_student: true,
        student_email: student.email,
        event_id: event.event_id,
        created_at: selectedDate,
      };

      // if (notifyParent) {
      //   body.parent_email = profile.parent_email;
      // }

      setCommentText("");
      socketContext.socket.emit("saveProfileComment", body);

      // if (notifyStudent) {
      //   let notificationTitle = "Σχόλιο από καθηγητή";
      //   let notificationDescription =
      //     "Έχεις νέο σχόλιο από καθηγητή στο προφίλ σου";

      //   let args = {
      //     notification: {
      //       title: notificationTitle,
      //       body: notificationDescription,
      //     },
      //   };

      //   const notificationBody = {
      //     user_id: profile.user_id,
      //     notification_body: args,
      //     notification_type: "new-profile-comment",
      //   };

      //   socketContext.socket.emit("sendNotification", notificationBody);
      // }
    }
  };

  return (
    <div className={"comment-item"}>
      <Link
        to={"/profile?user-id=" + student.user_id}
        className="comment-item__header"
      >
        <div className="header__img">
          <img
            src={
              student.profile_picture
                ? student.profile_picture
                : "resources/student.png"
            }
            alt={"student"}
          />
        </div>
        <div className="header__info">
          <div className="header__info-main">
            <span className="name">
              <span>{student.first_name}</span>
              <span>{student.last_name}</span>
            </span>
            <span className="date">{}</span>
          </div>
          <div className="header__info-details"></div>
        </div>
      </Link>
      <div className="comment-item__text">
        {comment ? (
          <div className="comment-item__text-content">
            <span>{comment.description}</span>
          </div>
        ) : (
          <div className="comment-item__text-input">
            <input
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              placeholder="Εισάγεται σχόλια για την επίδωση του μαθητή στο μάθημα..."
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents the default action of Enter key in a form
                  saveComment();
                }
              }}
            />
            <BiChevronRight
              onClick={() => saveComment()}
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CommentItem;
