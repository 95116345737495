import { createSlice } from '@reduxjs/toolkit'

export const classNamesSlice = createSlice({
    name: 'classes',
    initialState: {
        value: [{}],
        selectedClassId: -1,
        items: [{}],
        newClassCreatedId: -1,
    },  
    reducers: {
        setClasses: (state, action) => {
            state.value = action.payload;
        },
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setCurrentClassId: (state, action) => {
            state.selectedClassId = action.payload;
        },
        setNewClassCreatedId: (state, action) => {
            state.newClassCreatedId = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setClasses, setCurrentClassId, setItem, setNewClassCreatedId} = classNamesSlice.actions

export default classNamesSlice.reducer