import React from "react";
import { useState, useContext } from "react";
import "./side-filters.scss";
import "../../scss/main.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentClassId } from "./classNamesSlice";
import { setNewClassCreatedId } from "./classNamesSlice";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { SocketContext } from "../../app/socket";
import { useNavigate } from "react-router-dom";
import {
  BiPlus,
  BiCheck,
  BiX,
  BiTime,
  BiLock,
  BiRightArrow,
  BiGridVertical,
  BiArchive,
  BiDesktop,
  BiFoodMenu,
  BiTrim,
  BiTrash,
  BiAbacus,
} from "react-icons/bi";
import { setCurrentChapterId } from "./chaptersSlice";
import { setTopFilter } from "./topFilterSlice";
import ClassItem from "./classItem";
import LoaderCard from "../LoaderCard/loaderCard";
import { iconStyle } from "../../utils/generalUtils";

const SideFilters = ({
  hasTopFilters,
  toggleChapters = false,
  addClassShow,
  chapterSubmenu = false,
  addTopMargin,
  isForQuiz,
  onlyWithQuiz,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);

  const [classes, setClasses] = useState([]);
  const [gotClasses, setGotClasses] = useState(false);

  const [chapters, setChapters] = useState([]);

  const [activeClassIndex, setActiveClassIndex] = useState(-1);

  const [activeChapterIndex, setActiveChapterIndex] = useState(-1);

  const [showAddClassConfig, setShowAddClassConfig] = useState(false);

  const [chapterMode, setChapterMode] = useState(false);

  const [showMobileNav, setShowMobileNav] = useState(false);

  const [classesWithQuiz, setClassesWithQuiz] = useState([]);

  useEffect(() => {
    dispatch(setCurrentClassId(-1));
    dispatch(setNewClassCreatedId(-1));
    const cleanUpGetClasses = getClasses();
    if (onlyWithQuiz) {
      getClassesWithQuiz();
    }
    getGrades();
    return () => {
      cleanUpGetClasses();
    };
  }, []);

  useEffect(() => {
    if (activeClassIndex !== -1 && (toggleChapters || chapterSubmenu)) {
      setChapters([]);
      return getChapters();
    }
  }, [activeClassIndex]);

  useEffect(() => {
    if (showMobileNav) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showMobileNav]);

  const [grades, setGrades] = useState([]);
  const getGrades = () => {
    let args = {};

    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);
    socketContext.socket.on("refreshGrades", () => {
      socketContext.socket.emit("getGrades", args);
    });
  };

  const getClassesWithQuiz = () => {
    let args = {};

    const getClassesWithQuizListener = (data) => {
      console.log(data);
      setClassesWithQuiz(data);
    };

    socketContext.socket.on("classesWithQuiz", getClassesWithQuizListener);
    socketContext.socket.emit("getClassesWithQuiz", args);
    socketContext.socket.on("refreshClassesWithQuiz", () => {
      socketContext.socket.emit("getClassesWithQuiz", args);
    });
  };

  const populateGradeList = () => {
    return grades.map((grade) => {
      return (
        <option key={"grade " + grade.grade_id} value={grade.grade_id}>
          {grade.grade_name}
        </option>
      );
    });
  };

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setGotClasses(true);
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const classItemClick = (e, classIndex) => {
    if (e.target.tagName !== "path" && e.target.tagName !== "svg") {
      setActiveClassIndex(classIndex);
      dispatch(setCurrentClassId(classIndex));
      dispatch(setCurrentChapterId(-1));
      setActiveChapterIndex(-1);
    }
  };

  const getChapters = () => {
    let args = {
      class_id: activeClassIndex,
    };
    const getChaptersListener = (data) => {
      setChapters(data);
    };

    const refreshChaptersListener = () => {
      socketContext.socket.emit("getChapters", args);
    };

    socketContext.socket.on("chapters", getChaptersListener);
    socketContext.socket.emit("getChapters", args);
    socketContext.socket.on("refreshChapters", refreshChaptersListener);

    return () => {
      socketContext.socket.off("getChapters", getChaptersListener);
      socketContext.socket.off("chapters", getChaptersListener);
      socketContext.socket.off("refreshChapters", refreshChaptersListener);
    };
  };

  const deleteChapter = (chapterId) => {
    const body = {
      chapter_id: chapterId,
    };
    socketContext.socket.emit("deleteChapter", body);
    setActiveChapterIndex(-1);
  };

  const chapterClick = (chapterIndex) => {
    setActiveChapterIndex(chapterIndex);
    dispatch(setCurrentChapterId(chapterIndex));
  };

  const showClasses = () => {
    setChapterMode(false);
    setActiveClassIndex(-1);
    setActiveChapterIndex(-1);
  };

  const populateClasses = () => {
    const sortedClasses = sortClasses();
    return sortedClasses.map((classItem, index) => {
      if (classItem.class_id != -10) {
        return (
          <ClassItem
            key={"classItem" + index}
            classItem={classItem}
            index={index}
            activeClassIndex={activeClassIndex}
            activeChapterIndex={activeChapterIndex}
            profile={profile}
            chapterSubmenu={chapterSubmenu}
            classItemClick={classItemClick}
            populateChapterSubmenu={populateChapterSubmenu}
            disabled={
              classesWithQuiz.some(
                (cls) => cls.class_id == classItem.class_id
              ) || !onlyWithQuiz
                ? false
                : true
            }
          ></ClassItem>
        );
      } else {
        return (
          <div className="side-filters__nav-list-item-line">
            <span>{classItem.grade_name}</span>
          </div>
        );
      }
    });
  };

  const sortClasses = () => {
    const sortedClasses = classes;

    const withHeaders = [];
    let previousGrade = null;

    sortedClasses.forEach((classItem, index) => {
      if (classItem.grade_name != previousGrade) {
        withHeaders.push({
          class_id: -10,
          type: "header",
          grade_name: classItem.grade_name,
        });
        previousGrade = classItem.grade_name;
      }
      withHeaders.push(classItem);
    });
    return withHeaders;
  };

  const populateClassLoaders = () => {
    const card = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return card.map((item, index) => {
      return (
        <LoaderCard
          key={"loaderCard" + index}
          cardSum={1}
          width={100}
          size="large"
        />
      );
    });
  };

  const populateChapterSubmenu = () => {
    return chapters.map((chapterItem, index) => {
      return (
        <div
          key={"chapterSubmenu" + index}
          className={
            "chapter-list__item " +
            (activeChapterIndex === chapterItem.id ? "active" : "")
          }
          onClick={() => chapterClick(chapterItem.id)}
        >
          {chapterItem.chapter_name}
          {profile.user_type === "admin" && (
            <BiTrash
              className="chapter-list__item-delete"
              size={"25px"}
              color={"#FF6D6D"}
              style={iconStyle("transparent")}
              onClick={() => deleteChapter(chapterItem.id)}
            />
          )}
        </div>
      );
    });
  };

  const populateChapters = () => {
    return chapters.map((chapterItem, index) => {
      return (
        <div
          key={"sideChapteraaa" + index}
          className={
            "side-filters__nav-list-item " +
            (activeChapterIndex === chapterItem.id ? "active" : "")
          }
          onClick={() => chapterClick(chapterItem.id)}
        >
          {chapterItem.chapter_name}
        </div>
      );
    });
  };

  const [topFilterSelected, setTopFilterSelected] = useState("all");

  const topFilterClick = (filterIndex) => {
    setTopFilterSelected(filterIndex);
    dispatch(setTopFilter(filterIndex));
  };

  const [addClassName, setAddClassName] = useState("");
  const [newClassGrade, setNewClassGrade] = useState(1);

  const addClass = () => {
    const body = {
      class_name: addClassName,
      grade_id: newClassGrade,
      type: "add",
    };

    socketContext.socket.emit("updateClasses", body);

    const getLatestClassId = (data) => {
      let class_id = data;
      setActiveClassIndex(class_id);
      dispatch(setNewClassCreatedId(class_id));
      dispatch(setCurrentClassId(class_id));
    };

    socketContext.socket.on("latestClassId", getLatestClassId);
    setShowAddClassConfig(false);
  };

  return (
    <div className="side-filters">
      {showAddClassConfig ? (
        <div
          className={"modal-background show "}
          onClick={() => setShowAddClassConfig(false)}
        ></div>
      ) : (
        ""
      )}
      <div
        className={"side-filters__popup " + (showAddClassConfig ? "open" : "")}
      >
        <div className="side-filters__popup-container">
          <div
            className="side-filters__popup-container-close"
            onClick={() => {
              setShowAddClassConfig(false);
            }}
          >
            <BiX
              className="side-filters__nav-close"
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f2f2f2")}
            />
          </div>
          {profile.user_type !== "student" && (
            <div className="config">
              <div className="config__container">
                <label className="config__container-title">
                  Διαμόρφωση νέου μαθήματος
                </label>
                <div className="config__container-name">
                  <input
                    className="input"
                    placeholder="Όνομα μαθήματος"
                    type="text"
                    onChange={(e) => setAddClassName(e.target.value)}
                  />
                </div>
                {grades && grades.length ? (
                  <div className="config__container-grade input-shadow">
                    <select
                      className="select"
                      onChange={(e) => setNewClassGrade(e.target.value)}
                    >
                      {populateGradeList()}
                    </select>
                  </div>
                ) : (
                  <span>
                    Για να προσθέσετε νέο μάθημα πρώτα διαμορφώστε τις τάξεις
                    μέσω των ρυθμίσεων...
                  </span>
                )}
              </div>
              {grades && grades.length ? (
                <div className="config__options">
                  <div
                    className="cta config__options-btn"
                    onClick={() => addClass()}
                  >
                    Αποθήκευση
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <div className={"side-filters__mobile " + (isForQuiz ? "for-quiz" : "")}>
        <div className="cta" onClick={() => setShowMobileNav(true)}>
          <BiAbacus
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
          {/* <span>Φίλτρα</span> */}
        </div>
      </div>
      <div
        className={
          "side-filters__nav no-scrollbar " +
          (showMobileNav ? "show" : "") +
          (addTopMargin ? " addTopMargin" : "")
        }
      >
        <div
          className="side-filters__nav-mobile-close"
          onClick={() => {
            setShowMobileNav(false);
          }}
        >
          <BiX size={"35px"} color={"#cccccc"} style={iconStyle("#f2f2f2")} />
        </div>
        {hasTopFilters &&
          (profile.user_type === "professor" ||
            profile.user_type === "admin") && (
            <div className="container types">
              <div className="side-filters__nav-title">
                Ηλεκτρονικές υποβολές
              </div>
              <div className="side-filters__types">
                <div
                  className={"side-filters__types-item full"}
                  onClick={() => navigate("/exams-submitted")}
                >
                  <BiTrim
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                  <span>Υποβολές e-Διαγωνισμάτων</span>
                </div>
              </div>
            </div>
          )}
        {hasTopFilters ? (
          <div className="container types">
            <div className="side-filters__nav-title">Τύπος</div>
            <div className="side-filters__types">
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "all" ? "active" : "")
                }
                onClick={() => topFilterClick("all")}
              >
                <BiGridVertical
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                {/* <img src="/resources/exam-icon.png" alt="Διαγώνισμα" /> */}
                <span>Όλα</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "test" ? "active" : "")
                }
                onClick={() => topFilterClick("test")}
              >
                <img src="/resources/test-icon.png" alt="Διαγώνισμα" />
                <span>Τεστ</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "exam" ? "active" : "")
                }
                onClick={() => topFilterClick("exam")}
              >
                <img src="/resources/classroom-icon.png" alt="Διαγώνισμα" />
                <span>Διαγωνίσματα</span>
              </div>
              <div
                className={
                  "side-filters__types-item half " +
                  (topFilterSelected === "online-exam" ? "active" : "")
                }
                onClick={() => topFilterClick("online-exam")}
              >
                <img src="/resources/online-exam-icon.png" alt="Διαγώνισμα" />
                <span>e-Διαγωνίσματα</span>
              </div>
              {/* <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === "test" ? "active" : "")
                }
                onClick={() => topFilterClick("test")}
              >
                <BiArchive
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Τέστ</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === "exam" ? "active" : "")
                }
                onClick={() => topFilterClick("exam")}
              >
                <BiFoodMenu
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Διαγωνίσματα</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === "online-exam" ? "active" : "")
                }
                onClick={() => topFilterClick("online-exam")}
              >
                <BiDesktop
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>e-Διαγωνίσματα</span>
              </div> */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {hasTopFilters &&
          (profile.user_type === "professor" ||
            profile.user_type === "admin") && (
            <div className="container types">
              <div className="side-filters__nav-title">Τύπος</div>
              <div className="side-filters__types">
                <div
                  className={
                    "side-filters__types-item " +
                    (topFilterSelected === 0 ? "active" : "")
                  }
                  onClick={() => topFilterClick(0)}
                >
                  <BiGridVertical
                    size={"40px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                  <span>Όλα</span>
                </div>
                <div
                  className={
                    "side-filters__types-item " +
                    (topFilterSelected === 10 ? "active" : "")
                  }
                  onClick={() => topFilterClick(10)}
                >
                  <BiArchive
                    size={"40px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                  <span>Drafts</span>
                </div>
                <div
                  className={
                    "side-filters__types-item " +
                    (topFilterSelected === 11 ? "active" : "")
                  }
                  onClick={() => topFilterClick(11)}
                >
                  <BiFoodMenu
                    size={"40px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                  <span>Ζωντανά</span>
                </div>
                <div
                  className={
                    "side-filters__types-item " +
                    (topFilterSelected === 12 ? "active" : "")
                  }
                  onClick={() => topFilterClick(12)}
                >
                  <BiDesktop
                    size={"40px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                  <span>Online</span>
                </div>
              </div>
            </div>
          )}
        {hasTopFilters && profile.user_type === "student" && (
          <div className="container types">
            <div className="side-filters__nav-title">Κατάσταση</div>
            <div className="side-filters__types">
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 0 ? "active" : "")
                }
                onClick={() => topFilterClick(0)}
              >
                <BiGridVertical
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Όλα</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 1 ? "active" : "")
                }
                onClick={() => topFilterClick(1)}
              >
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={6}
                  className={"card__details-score"}
                  value="70"
                  duration={1.4}
                  text="%"
                />
                <span>Βαθμολογημενα</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 2 ? "active" : "")
                }
                onClick={() => topFilterClick(2)}
              >
                <BiCheck
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Απεσταλμένα</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 3 ? "active" : "")
                }
                onClick={() => topFilterClick(3)}
              >
                <BiTime
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Σε Εξέλιξη</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 4 ? "active" : "")
                }
                onClick={() => topFilterClick(4)}
              >
                <BiRightArrow
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Ανοιχτό</span>
              </div>
              <div
                className={
                  "side-filters__types-item " +
                  (topFilterSelected === 5 ? "active" : "")
                }
                onClick={() => topFilterClick(5)}
              >
                <BiLock
                  size={"40px"}
                  color={"#cccccc"}
                  style={iconStyle("#f2f2f2")}
                />
                <span>Κλειδωμένο</span>
              </div>
            </div>
          </div>
        )} */}

        {!chapterMode && (
          <div className="container classes">
            <div className="side-filters__nav-title">Μαθήματα</div>
            <div className="side-filters__nav-list">
              {addClassShow && (
                <div className={"side-filters__nav-list-item-button add "}>
                  <div
                    className={"add-container "}
                    onClick={() => {
                      setShowAddClassConfig(true);
                      socketContext.socket.emit("refreshAllUsersWithParams");
                    }}
                  >
                    <BiPlus
                      className={
                        "side-filters__container-title-arrow " +
                        (showAddClassConfig ? "active" : "")
                      }
                      size={"30px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                    <span>Προσθήκη Μαθήματος</span>
                  </div>
                </div>
              )}
              <div
                className={
                  "side-filters__nav-list-item-button " +
                  (activeClassIndex === -1 ? "active" : "")
                }
                onClick={(e) => classItemClick(e, -1)}
              >
                <span>Όλα</span>
              </div>
              {gotClasses ? (
                populateClasses()
              ) : (
                <div className="loading-classes">{populateClassLoaders()}</div>
              )}
            </div>
          </div>
        )}

        {chapterMode && (
          <div className="container classes">
            <div className="side-filters__nav-title">Κεφάλαια</div>
            <div className="side-filters__nav-list">
              <div
                className={"side-filters__nav-list-item"}
                onClick={showClasses}
              >
                Πίσω
              </div>
              {populateChapters()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideFilters;
