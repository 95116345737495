import React, { useState, useEffect, useContext, useRef } from "react";
import "./date-section.scss";
import { SocketContext } from "../../../../app/socket";
import TimeLine from "./timeline";
import { useEventContext } from "../../eventContext";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

function DateSection({ eventType, eventDepartment, calendarDate }) {
  const socketContext = useContext(SocketContext);
  const scrollContainerRef = useRef(null);

  const [classrooms, setClassrooms] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  const [classroomSearch, setClassroomSearch] = useState("");

  const { eventClassrooms, setEventClassrooms } = useEventContext();
  const { eventStartDate, setEventStartDate } = useEventContext();
  const { setEventEndDate } = useEventContext();

  const [selectedDate, setSelectedDate] = useState(
    calendarDate
      ? calendarDate
      : eventStartDate
      ? new Date(eventStartDate)
      : new Date()
  );

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  };

  const defaultStartTime = new Date();
  const defaultEndTime = new Date(
    defaultStartTime.getTime() + 2 * 60 * 60 * 1000
  );

  const [startTime, setStartTime] = useState(formatTime(defaultStartTime));
  const [endTime, setEndTime] = useState(formatTime(defaultEndTime));

  const [monthEvents, setMonthEvents] = useState([]);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateArrowsVisibility);
    return () => window.removeEventListener("resize", updateArrowsVisibility);
  }, []);

  useEffect(() => {
    return getMonthEvents();
  }, [selectedDate]);

  useEffect(() => {
    let date =
      selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    const [hours, minutes] = startTime.split(":").map(Number);
    date.setHours(hours, minutes, 0);

    // Adjust for the timezone offset to get the correct local time
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    date = new Date(date.getTime() - timezoneOffsetInMinutes * 60000);

    const formattedDate = date.toISOString().replace("T", " ").substring(0, 19);

    setEventStartDate(formattedDate);
  }, [selectedDate, startTime]);

  useEffect(() => {
    let date =
      selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
    const [hours, minutes] = endTime.split(":").map(Number);
    date.setHours(hours, minutes, 0);

    // Adjust for the timezone offset to get the correct local time
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    date = new Date(date.getTime() - timezoneOffsetInMinutes * 60000);

    const formattedDate = date.toISOString().replace("T", " ").substring(0, 19);

    setEventEndDate(formattedDate);
  }, [selectedDate, endTime]);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(calendarDate ? new Date(calendarDate) : currentDate);
    }
  }, []);

  useEffect(() => {
    const cleanUpGetClassrooms = getClassRooms();
    return () => {
      cleanUpGetClassrooms();
    };
  }, [classroomSearch]);

  useEffect(() => {
    updateArrowsVisibility();
  }, [classrooms]);

  const updateArrowsVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollWidth > container.clientWidth + container.scrollLeft
      );
    }
  };

  const handleScrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({
        left: -0.9 * container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({
        left: 0.9 * container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const getMonthEvents = () => {
    if (selectedDate) {
      let args = {
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      };

      const getMonthEventsListener = (data) => {
        setMonthEvents(data);
      };

      const refreshMonthEventsListener = () => {
        socketContext.socket.emit("getMonthEvents", args);
      };

      socketContext.socket.on("monthEvents", getMonthEventsListener);
      socketContext.socket.emit("getMonthEvents", args);
      socketContext.socket.on("refreshEvents", refreshMonthEventsListener);

      return () => {
        socketContext.socket.off("getMonthEvents", getMonthEventsListener);
        socketContext.socket.off("monthEvents", getMonthEventsListener);
        socketContext.socket.off("refreshEvents", refreshMonthEventsListener);
      };
    }
  };

  const getMonthDays = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    // Adjust to make Monday the first day of the week
    return firstDay == 0 ? 6 : firstDay - 1;
  };
  const dayNamesShort = ["Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ", "Κυρ"];

  const renderDayNames = () => {
    return dayNamesShort.map((dayName, index) => (
      // Adjust index by 1 to start the week on Monday (1)
      <div key={index} className="day-name">
        {dayName}
      </div>
    ));
  };

  const renderDays = () => {
    const days = [];
    const monthDays = getMonthDays(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );
    const firstDay = getFirstDayOfMonth(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= monthDays; day++) {
      days.push(
        <div
          key={day}
          className={
            "day " +
            (day == selectedDate?.getDate() ? "selected " : "") +
            (day == new Date().getDate() &&
            selectedDate.getMonth() == new Date().getMonth()
              ? " current "
              : " ") +
            (monthEvents &&
            day >= 1 &&
            day &&
            monthEvents[day - 1] &&
            monthEvents[day - 1].Events != "No events"
              ? monthEvents[day - 1].Events == "lecture"
                ? "has-lecture"
                : "" + monthEvents[day - 1].Events == "exam"
                ? "has-exam"
                : "" + monthEvents[day - 1].Events == "lecture&test"
                ? "has-lecture-and-test"
                : "" + monthEvents[day - 1].Events == "lecture&exam&test"
                ? "has-lecture-and-exam-and-test"
                : "" + monthEvents[day - 1].Events == "exam&test"
                ? "has-exam-and-test"
                : "" + monthEvents[day - 1].Events == "test"
                ? "has-test"
                : "" + monthEvents[day - 1].Events == "lecture&exam"
                ? "has-lecture-and-exam"
                : ""
              : "")
          }
          onClick={() =>
            setSelectedDate(
              new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
            )
          }
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const changeMonth = (increment) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + increment, 1)
    );
  };

  const monthNames = [
    "Ιανουάριος",
    "Φεβρουάριος",
    "Μάρτιος",
    "Απρίλιος",
    "Μάιος",
    "Ιούνιος",
    "Ιούλιος",
    "Αύγουστος",
    "Σεπτέμβριος",
    "Οκτώβριος",
    "Νοέμβριος",
    "Δεκέμβριος",
  ];

  const getClassRooms = () => {
    let args = { classroom_name: classroomSearch };

    const getClassRoomsListener = (data) => {
      if (data && data[0] && !eventClassrooms.length) {
        setEventClassrooms([data[0].classroom_id]);
      }
      setClassrooms(data);
      updateArrowsVisibility();
    };

    const refreshClassRoomsListener = () => {
      socketContext.socket.emit("getClassRooms", args);
    };

    socketContext.socket.on("classrooms", getClassRoomsListener);
    socketContext.socket.emit("getClassRooms", args);
    socketContext.socket.on("refreshClassRooms", refreshClassRoomsListener);

    return () => {
      socketContext.socket.off("getClassRooms", getClassRoomsListener);
      socketContext.socket.off("classrooms", getClassRoomsListener);
      socketContext.socket.off("refreshClassRooms", refreshClassRoomsListener);
    };
  };

  const populateClassrooms = () => {
    return classrooms.map((classroom, index) => {
      return (
        <div
          key={"professorsItemNewEvent" + index}
          className={
            "item " +
            (eventClassrooms.includes(classroom.classroom_id) ? "active" : "")
          }
          onClick={() => classroomClick(classroom.classroom_id)}
        >
          {classroom.classroom_name}
        </div>
      );
    });
  };

  const classroomClick = (clickedId) => {
    let temp = eventClassrooms;
    if (eventType == "lecture" && eventClassrooms.length) {
      setEventClassrooms([]);
      temp = [];
    }
    if (eventClassrooms.length == 1 && eventClassrooms[0] == -1) {
      temp = [];
    }
    if (eventClassrooms.includes(clickedId)) {
      const newSelectedClassrooms = eventClassrooms.filter(
        (id) => id !== clickedId
      );
      if (newSelectedClassrooms.length == 0) {
        temp = [-1];
      } else {
        temp = newSelectedClassrooms;
      }
    } else {
      temp = [...temp, clickedId];
    }
    setEventClassrooms(temp);
  };

  return (
    <div className={"date-section no-scrollbar"}>
      <div className="config">
        <div className="wrapper">
          <div className="config__date">
            <div className="date-picker">
              <div className="header">
                <button onClick={() => changeMonth(-1)}>&lt;</button>
                <span>{`${
                  monthNames[currentDate.getMonth()]
                } ${currentDate.getFullYear()}`}</span>
                <button onClick={() => changeMonth(1)}>&gt;</button>
              </div>
              <div className="day-names">{renderDayNames()}</div>
              <div className="days">{renderDays()}</div>
            </div>
          </div>
          {window.innerWidth <= 700 ? (
            <TimeLine
              eventType={eventType}
              eventDepartment={eventDepartment}
              selectedClassrooms={eventClassrooms}
            />
          ) : (
            ""
          )}
          <div className="classrooms">
            <span className="label">
              {eventType == "lecture"
                ? "Επιλέξτε αίθουσα"
                : "Επίλεξτε αίθουσες"}
            </span>
            <div className="list-wrapper">
              <input
                className="input"
                placeholder="Αναζήτηση αίθουσας"
                value={classroomSearch}
                onChange={(e) => setClassroomSearch(e.target.value)}
              />
              {showLeftArrow && (
                <div className="scroll-arrow left" onClick={handleScrollLeft}>
                  <BiChevronLeft
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                </div>
              )}
              <div
                className="classrooms__list no-scrollbar"
                ref={scrollContainerRef}
                onScroll={updateArrowsVisibility}
              >
                {populateClassrooms()}
              </div>
              {showRightArrow && (
                <div className="scroll-arrow right" onClick={handleScrollRight}>
                  <BiChevronRight
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth > 700 ? (
        <TimeLine
          eventType={eventType}
          eventDepartment={eventDepartment}
          selectedClassrooms={eventClassrooms}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default DateSection;
