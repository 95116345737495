import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Children,
} from "react";
import { SocketContext } from "../../../../app/socket";
import { motion } from "framer-motion";
import ProfessorEventCard from "../../../../components/EventCard/exam/professor/professorEventCard";
import { useSelector } from "react-redux";
import StudentEventCard from "../../../../components/EventCard/exam/student/studentEventCard";
import {
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiAlarm,
  BiBookAlt,
  BiTime,
  BiCheck,
  BiAlarmOff,
  BiCaretRightCircle,
  BiLockAlt,
  BiLogInCircle,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { CircularProgressbar } from "react-circular-progressbar";
import ParentEventCard from "../../../../components/EventCard/exam/parent/parentEventCard";

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  // Define options for displaying hours and minutes
  const options = {
    hour: "2-digit", // two-digit hour
    minute: "2-digit", // two-digit minute
    hour12: false, // use 24-hour format
  };
  const formatter = new Intl.DateTimeFormat("el-GR", options);

  return formatter.format(date);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat("el-GR", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
};

function ParentExamsCard({ event }) {
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const children = useSelector((state) => state.profile.children);

  const [child, setChild] = useState();

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isExpanded]);

  useEffect(() => {
    const temp = children.find((child) => child.user_id == event.student_id);
    setChild(temp);
  }, []);

  useEffect(() => {
    if (window.innerWidth > 700) {
      if (event.score) {
        if (event.type == "exam" || event.type == "test") {
          setModalHeight(50);
          setModalWidth(90);
          setModalTop(0.25);
          setModalLeft(0.05);
        }
      } else {
        setModalHeight(40);
        setModalWidth(60);
        setModalTop(0.3);
        setModalLeft(0.25);
      }
    } else {
      if (event.score) {
        if (event.type == "exam" || event.type == "test") {
          setModalHeight(95);
          setModalWidth(90);
          setModalTop(0.025);
          setModalLeft(0.05);
        }
      } else {
        setModalHeight(40);
        setModalWidth(60);
        setModalTop(0.3);
        setModalLeft(0.25);
      }
    }
  }, [event]);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;

      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
        height: modalHeight + "vh",
      };
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const closeCard = () => {
    setIsExpanded(false);
  };

  function formatScore(score, maxRank) {
    const result = (score * 100) / maxRank;
    const roundedResult = Math.floor(result);
    const hasDecimal = result % 1 !== 0;

    if (hasDecimal) {
      return result.toFixed(1);
    } else {
      return roundedResult.toString();
    }
  }

  const getStatusIcon = () => {
    if (event.score != null) {
      return (
        <div className="student-exams__status">
          <CircularProgressbar
            pathColor="{red}"
            strokeWidth={6}
            value={(event.score * 100) / event.max_rank}
            duration={1.4}
            text={`${formatScore(event.score, event.max_rank)}%`}
          />
        </div>
      );
    } else {
      if (event.type != "online-exam") {
        return (
          <div className="student-exams__status">
            <BiTime
              size={"40px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          </div>
        );
      } else {
        if (
          new Date() <= new Date(event.start_at) ||
          new Date() >= new Date(event.finish_at)
        ) {
          return (
            <div className="student-exams__status">
              <BiLockAlt
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
          );
        } else if (event.answer_text) {
          return (
            <div className="student-exams__status">
              <BiTime
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
          );
        } else if (event.state == "in-progress") {
          return (
            <div className="student-exams__status">
              <BiLogInCircle
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
          );
        } else if (
          new Date() <= new Date(event.finish_at) &&
          new Date() >= new Date(event.start_at)
        ) {
          return (
            <div className="student-exams__status">
              <BiCaretRightCircle
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            </div>
          );
        }
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={
        "exams__card parent student-exams__list-card " +
        (isExpanded ? " is-expanded " : " is-collapsed ") +
        event.type
      }
    >
      {!isExpanded ? getStatusIcon() : ""}
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        layout
        initial={false} // You can adjust this as needed
        className={
          "exams__card-content " + (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style} // Apply dynamic style here
      >
        {isExpanded ? (
          <ParentEventCard event={event} closeCard={closeCard} />
        ) : (
          <div className="exams__card-content-closed">
            <div className="title__wrapper">
              <span className="title">{event.title}</span>
            </div>
            <div className="closed__details">
              <div className="detail-item">
                <BiBookAlt
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <span>{event.class_name}</span>
              </div>
              <div className="detail-item">
                {event.type == "online-exam" ? (
                  <BiCalendarCheck
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  <BiCalendar
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}
                <span>{formatDate(event.start_at)}</span>
              </div>
              <div className="detail-item">
                {event.type == "online-exam" ? (
                  <BiCalendarX
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  <BiAlarm
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}

                <span>
                  {event.type == "online-exam"
                    ? formatDate(event.finish_at)
                    : formatDateTime(event.start_at) +
                      " - " +
                      formatDateTime(event.finish_at)}
                </span>
              </div>
              {child ? (
                <div className="detail-item child">
                  <img
                    className="profile-img__img"
                    src={
                      child.profile_picture
                        ? child.profile_picture
                        : "resources/student.png"
                    }
                    alt={"student"}
                  />
                  <span>
                    {child.first_name} {child.last_name}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default ParentExamsCard;
