import { cookieUtils } from "./cookie-parser";

export const tokenToCookie = function (userObject, reload) {
  console.log(" user obkect ");
  console.log(userObject);
  if (userObject.birthday) {
    delete userObject.birthday;
  }

  delete userObject.grade;
  delete userObject.last_quiz;
  delete userObject.first_name;
  delete userObject.last_name;
  delete userObject.profile_picture;
  delete userObject.email;
  delete userObject.password;

  const cookie = JSON.stringify(userObject);
  console.log(" final cookie ");
  console.log(cookie);

  cookieUtils.setCookie("auth", cookie, 200);
  if (reload) {
    window.location.reload();
  }
};

export const removeToken = function () {
  cookieUtils.eraseCookie("auth");
};
