import { createSlice } from "@reduxjs/toolkit";

export const navbarSlice = createSlice({
  name: "profile",
  initialState: {
    value: {},
    environmentVars: {},
    hasCameraAccess: 1,
    userTypePermissions: {},
    children: {},
    snowFall: false,
    openChats: {},
    onlineUsers: {},
    schoolLogo: null,
    generalSettings: {},
  },
  reducers: {
    setProfileData: (state, action) => {
      let data = action.payload;
      if (data.birthday)
        data.birthday = new Date(data.birthday).toDateInputValue();
      state.value = data;
    },
    setSnowFall: (state, action) => {
      state.snowFall = action.payload;
    },
    setHasCameraAccess: (state, action) => {
      state.hasCameraAccess = action.payload;
    },
    setEnvironmentVars: (state, action) => {
      state.environmentVars = action.payload;
    },
    setUserTypePermissions: (state, action) => {
      state.userTypePermissions = action.payload;
    },
    setChildren: (state, action) => {
      state.children = action.payload;
    },
    setOpenChats: (state, action) => {
      state.openChats = action.payload;
    },
    setSchoolLogo: (state, action) => {
      state.schoolLogo = action.payload;
    },
    closeOpenChat: (state, action) => {
      const chatId = action.payload;
      if (state.openChats[chatId]) {
        delete state.openChats[chatId];
      }
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    setGeneralSettings: (state, action) => {
      state.generalSettings = action.payload;
    },
  },
});

Date.prototype.toDateInputValue = function () {
  let local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

// Action creators are generated for each case reducer function
export const {
  setProfileData,
  setHasCameraAccess,
  setEnvironmentVars,
  setChildren,
  setUserTypePermissions,
  setSnowFall,
  setOpenChats,
  closeOpenChat,
  setOnlineUsers,
  setSchoolLogo,
  setGeneralSettings,
} = navbarSlice.actions;

export default navbarSlice.reducer;
