import { useContext, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "../../../app/socket";
import { iconStyle } from "../../../utils/generalUtils";
import "react-tabs/style/react-tabs.css";
import { BiPencil, BiPlus, BiX } from "react-icons/bi";
import "./add-department.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AddUsersSlide from "./AddUsersSlide/addUsersSlide";
import { useSelector } from "react-redux";

function AddDepartment({ setSelectedDepartment }) {
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dimensions, setDimensions] = useState({
    width: "50vw",
    height: "300px",
  });

  const [selectedProfessors, setSelectedProfessors] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [departmentName, setDepartmentName] = useState("");

  const carouselRef = useRef(null);

  useEffect(() => {
    if (!isExpanded) {
      setCurrentSlide(0);
      setSelectedProfessors([]);
      setSelectedStudents([]);
      if (carouselRef && carouselRef.current) {
        carouselRef.current.slickGoTo(0);
        setDimensions({
          width: "50vw",
          height: "300px",
        });
      }
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleSlideChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  const settings = {
    infinite: false,
    speed: 500,
    draggable: false,
    swipe: false, // Disable swipe for touch devices
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    afterChange: handleSlideChange,
  };

  const closeDepartmentModal = () => {
    setIsExpanded(false);
  };

  const createDepartment = () => {
    const body = {
      department_name: departmentName,
      class_id: sideFiltersClassId,
      type: "add",
    };
    socketContext.socket.emit("updateDepartments", body);

    const addedAllUsersToDepartmentListner = (department_id) => {
      // socketContext.socket.emit("refreshAllUsersWithParams");
      setDepartmentName("");
      closeDepartmentModal();
      setCurrentSlide(0);
      setSelectedDepartment(department_id);
      setSelectedProfessors([]);
      setSelectedStudents([]);
      carouselRef.current.slickGoTo(0);
    };

    const test = (data) => {
      const cleanUp = getLatestDepartmentId(data);
      return cleanUp();
    };

    const getLatestDepartmentId = (data) => {
      let department_id = data;
      socketContext.socket.on(
        "addedUsersToDepartment-" + department_id,
        addedAllUsersToDepartmentListner(department_id)
      );
      const allUsersToAdd = selectedProfessors.concat(selectedStudents);
      const body = {
        department_id: department_id,
        class_id: sideFiltersClassId,
        users_to_add: allUsersToAdd,
      };
      socketContext.socket.emit("addUsersToDepartment", body);
      return () => {
        socketContext.socket.off(
          "addedUsersToDepartment-" + department_id,
          addedAllUsersToDepartmentListner(department_id)
        );
        socketContext.socket.off(
          "addUsersToDepartment",
          addedAllUsersToDepartmentListner(department_id)
        );
        socketContext.socket.off("latestDepartmentId", test);
      };
    };

    socketContext.socket.on("latestDepartmentId", test);

    return () => {
      socketContext.socket.off("updateDepartments", getLatestDepartmentId);
    };
  };

  const nextSlide = () => {
    if (currentSlide >= 0) {
      setDimensions({
        width: "90vw",
        height: "80vh",
      });
    } else {
      setDimensions({
        width: "50vw",
        height: "300px",
      });
    }

    if (currentSlide === 2) {
      createDepartment();
    } else {
      carouselRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (currentSlide > 1) {
      setDimensions({
        width: "90vw",
        height: "80vh",
      });
    } else {
      setDimensions({
        width: "50vw",
        height: "300px",
      });
    }

    carouselRef.current.slickPrev();
  };

  return (
    <div
      className={
        "add-department users__departments-list-item " +
        (isExpanded ? " is-expanded" : " not-expanded")
      }
    >
      {isExpanded && (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      )}
      <div onClick={toggleExpand} className="preview">
        <BiPlus
          size={"30px"}
          color={"#cccccc"}
          style={iconStyle("transparent")}
        />
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="content"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: 1,
              scale: 1,
              width: dimensions.width,
              height: dimensions.height,
              top: "50%",
              left: "50%",
              translateX: "-50%",
              translateY: "-50%",
            }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
            style={{ position: "fixed" }}
          >
            <div onClick={toggleExpand} className="close-btn">
              <BiX size={"30px"} color={"#ccc"} style={iconStyle("#f8f8f8")} />
            </div>
            <div className="content__slider">
              <Slider {...settings} ref={carouselRef}>
                <div className="slide general-section">
                  <span>Είσαγεται το όνομα του τμήματος</span>
                  <input
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    placeholder="Όνομα Τμήματος"
                    className="input"
                  />
                </div>
                <div className="slide users-section">
                  <AddUsersSlide
                    userType={"professor"}
                    selectedUsers={selectedProfessors}
                    setSelectedUsers={setSelectedProfessors}
                  />
                </div>
                <div className="slide users-section">
                  <AddUsersSlide
                    userType={"student"}
                    selectedUsers={selectedStudents}
                    setSelectedUsers={setSelectedStudents}
                  />
                </div>
              </Slider>
            </div>
            <div className="content__nav">
              <button
                className={currentSlide == 0 ? "not-visbile" : ""}
                onClick={prevSlide}
              >
                Προηγούμενο
              </button>
              <button
                className={currentSlide == 2 ? "final" : ""}
                onClick={nextSlide}
              >
                {currentSlide == 2 ? "Δημιουργία" : "Επόμενο"}
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default AddDepartment;
