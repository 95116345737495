import "./active-users.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoaderCard from "../../../../components/LoaderCard/loaderCard";

function ActiveUsers() {
  const activeUsers = useSelector((state) => state.profile.onlineUsers);

  const populateActiveUsers = () => {
    return activeUsers.map((user, index) => {
      return (
        <Link
          className="active-user"
          key={"active-user" + user.user_id}
          to={"/profile?user-id=" + user.user_id}
        >
          <div className="active-user__img">
            <img
              alt={"profile"}
              src={
                user.profile_picture
                  ? user.profile_picture
                  : "resources/student.png"
              }
            />
          </div>
          <div className="active-user__name">
            <span>{user.first_name}</span>
            <span>{user.last_name}</span>
          </div>
        </Link>
      );
    });
  };

  return (
    <div className={"section active-users-section"}>
      <span className="section__title">Ενεργοί χρήστες</span>
      {activeUsers && activeUsers.length ? (
        <div className="active-users">{populateActiveUsers()}</div>
      ) : (
        ""
      )}

      {/* // : (
      //   <div className="active-users loading">
      //     <div className="loader">
      //       <LoaderCard cardSum={1} width={100} size="large" />
      //     </div>
      //     <div className="loader">
      //       <LoaderCard cardSum={1} width={100} size="large" />
      //     </div>
      //     <div className="loader">
      //       <LoaderCard cardSum={1} width={100} size="large" />
      //     </div>
      //     <div className="loader">
      //       <LoaderCard cardSum={1} width={100} size="large" />
      //     </div>
      //   </div>
      // ) */}
    </div>
  );
}

export default ActiveUsers;
