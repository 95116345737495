import { createContext, useReducer } from "react";
import { tokenUtils } from "../utils/token-utils";
import { io } from "socket.io-client";
import { isDevEnv } from "../utils/generalUtils";

export const SocketContext = createContext(null);
export const SocketDispatchContext = createContext(null);

export function SocketProvider({ children }) {
  const [socket, dispatch] = useReducer(socketReducer, initialSocket);

  return (
    <SocketContext.Provider value={socket}>
      <SocketDispatchContext.Provider value={dispatch}>
        {children}
      </SocketDispatchContext.Provider>
    </SocketContext.Provider>
  );
}

function socketReducer(tasks, action) {
  // switch (action.type) {
  //     case 'added': {
  //         // const token = tokenUtils.getBearerToken();
  //         // const socket = io(`http://${window.location.hostname}:3001`, token && {query: {token}});
  //
  //         return [...tasks, {
  //             id: action.id,
  //             text: action.text,
  //             done: false
  //         }];
  //     }
  //     case 'changed': {
  //         return tasks.map(t => {
  //             if (t.id === action.task.id) {
  //                 return action.task;
  //             } else {
  //                 return t;
  //             }
  //         });
  //     }
  //     case 'deleted': {
  //         return tasks.filter(t => t.id !== action.id);
  //     }
  //     default: {
  //         throw Error('Unknown action: ' + action.type);
  //     }
  // }
}
function getSocket() {
  const token = tokenUtils.getBearerToken();

  let opts = {
    transports: ["polling"],
  };

  return io(
    isDevEnv() ? "http://localhost:3001/" : "/",
    token
      ? {
          ...opts,
          query: { token },
        }
      : opts
  );
}

const initialSocket = {
  socket: getSocket(),
};
